import { useEffect, useState } from 'react'

/**
 * Decode HTML entities with the hook.
 *
 * https://stackoverflow.com/questions/5796718/html-entity-decode
 */
const useHtmlEntityDecode = (text: string): string => {
  const [decoded, setDecoded] = useState(text)

  useEffect(() => {
    const decode = (() => {
      const textArea = document.createElement('textarea')
      return (message: string): string => {
        textArea.innerHTML = message
        return textArea.value
      }
    })()

    setDecoded(decode(text))
  }, [text])

  return decoded
}

export default useHtmlEntityDecode
