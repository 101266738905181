import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ReviewPublic from 'utils/classes/ReviewPublic'
import ReviewCard from 'components/Card/Review'

type ReviewSliderProps = {
  reviews: ReviewPublic[]
  minHeight?: number
  minWidth?: number
}

const ReviewSlider = ({ reviews, minHeight = 500, minWidth = 1216 }: ReviewSliderProps) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="relative w-full overflow-x-hidden"
      style={{
        minHeight: `${minHeight}px`,
        minWidth: `${minWidth}px`,
      }}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <div
        className={classNames(
          'absolute top-0 left-0 animate-horizontalScrollPrimary flex flex-nowrap items-start w-full space-x-2',
          {
            'animation-paused': isHovered,
          },
        )}
      >
        {reviews.map((r) => (
          <ReviewCard key={`primary-${r.id}`} review={r} isInverse showPublishedBy />
        ))}
      </div>

      <div
        className={classNames(
          'absolute top-0 left-0 animate-horizontalScrollSecondary flex flex-nowrap items-start w-full space-x-2 ml-2',
          {
            'animation-paused': isHovered,
          },
        )}
      >
        {reviews.map((r) => (
          <ReviewCard key={`secondary-${r.id}`} review={r} isInverse showPublishedBy />
        ))}
      </div>
    </div>
  )
}

ReviewSlider.propTypes = {
  reviews: PropTypes.array,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
}

export default ReviewSlider
