import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

type ComponentProps = {
  title: string
  description: string
  className: any
  icon: any
  iconClass: string
}

const Component = ({ title, description, className, icon, iconClass }: ComponentProps) => {
  return (
    <div
      className={classnames(
        'py-6 pl-6 pr-4 bg-gradient border border-black rounded-3xl',
        className,
      )}
    >
      <div className="flex items-center mb-4">
        <span
          className={classnames(
            {
              'flex items-center justify-center mr-4': true,
              'w-12 h-12 inline-block': true,
              'rounded-lg': true,
            },
            iconClass,
          )}
        >
          {icon}
        </span>

        <h4 className="text-3xl font-bold font-heading">{title}</h4>
      </div>

      <p className="text-gray-700 leading-loose">{description}</p>
    </div>
  )
}

Component.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.any,
  icon: PropTypes.any,
  iconClass: PropTypes.string,
}

export default Component
