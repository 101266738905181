import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'

import GradientLabel from 'components/Label/GradientLabel'
import Card from 'components/Card/GradientCard'

const SecondSlide = () => {
  const { t } = useTranslation('pages')

  return (
    <div className="flex flex-col bg-white py-16 md:py-32">
      <div className={'md:mb-auto'} />

      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/2 mb-2 lg:mb-0">
            <div className="px-4 lg:pr-24">
              <div className="ml-2 mb-4">
                <Image
                  alt="diamond"
                  src={'/radicalIcons/retro/diamond.svg'}
                  width={30}
                  height={30}
                />
              </div>

              <h2 className="mb-8 font-extrabold font-heading">
                <div className="text-5xl sm:text-6xl md:text-7xl lg:text-7xl">
                  <span className="mr-2">
                    {t('index.slide2.title1')}{" "}
                    <GradientLabel isGreen>{t('index.slide2.title2')}</GradientLabel>{" "}
                    {t('index.slide2.title3')} <br /> {t('index.slide2.title4')}
                  </span>
                </div>
              </h2>

              <div className="md:text-lg lg:text-xl mb-8 text-gray-500 max-w-xl">
                {t('index.slide2.description')}
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 flex flex-wrap lg:-mx-4 px-4">
            <div className="w-full md:w-1/2 md:px-4">
              <Card
                title={t('index.slide2.options.read.title')}
                className={'mb-8'}
                description={t('index.slide2.options.read.description')}
                icon={<FontAwesomeIcon size="lg" className={'text-black'} icon={['far', 'eyes']} />}
                iconClass="bg-white border border-black shadow-bottomRightYellow"
              />

              <Card
                title={t('index.slide2.options.own.title')}
                className={'mb-8'}
                description={t('index.slide2.options.own.description')}
                icon={<FontAwesomeIcon size="lg" className={'text-black'} icon={['fas', 'key']} />}
                iconClass="bg-white border border-black shadow-bottomRightFuchsia"
              />

              {false && (
                <Card
                  title="Collect"
                  description="Reviews are a form of expression and can be appreciated"
                  icon={
                    <FontAwesomeIcon size="lg" className={'text-black'} icon={['fas', 'cubes']} />
                  }
                  iconClass="bg-white border border-black shadow-bottomRightIndigo"
                />
              )}
            </div>

            <div className="w-full md:w-1/2 lg:mt-20 md:px-4">
              <Card
                title={t('index.slide2.options.write.title')}
                className={'mb-8'}
                description={t('index.slide2.options.write.description')}
                icon={
                  <FontAwesomeIcon size="lg" className={'text-black'} icon={['fas', 'feather']} />
                }
                iconClass="bg-white border border-black shadow-bottomRightPink"
              />

              <Card
                title={t('index.slide2.options.sell.title')}
                description={t('index.slide2.options.sell.description')}
                icon={
                  <FontAwesomeIcon
                    size="lg"
                    className={'text-black'}
                    icon={['fas', 'sack-dollar']}
                  />
                }
                iconClass="bg-white border border-black shadow-bottomRightGreen"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={'md:mt-auto'} />
    </div>
  )
}

export default SecondSlide
