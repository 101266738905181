import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

type StopPropagationProps = {
  children: ReactElement | string | null
  className?: string
}

const StopPropagation = ({ children, className }: StopPropagationProps) => {
  return (
    <div role="button" className={classNames(className)} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  )
}

StopPropagation.propTypes = {
  className: PropTypes.string,
}

export default StopPropagation
