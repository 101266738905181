import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'

type TwemojiProps = {
  emoji: string
  height: number
  width: number
  className?: string
}

const Twemoji = ({ emoji, height, width, className }: TwemojiProps) => {
  if (!emoji) {
    throw new Error('You need to specify an emoji for the Twemoji component')
  }

  const img = emoji.codePointAt(0)?.toString(16)

  // const url = 'https://twemoji.maxcdn.com/v/latest/svg/';
  const url = 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/';

  return (
    <Image
      className={className}
      src={`${url}/${img}.svg`}
      height={height}
      width={width}
      alt={emoji}
    />
  )
}

Twemoji.propTypes = {
  emoji: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
}

export default memo(Twemoji)
