import { useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { ReactionValue } from '.'

type Item = {
  initialValue: ReactionValue
  value: ReactionValue | null
}

type ItemList = {
  [reactionId: string]: Item
}

const reactionsAtom = atom<ItemList>({})

/**
 * We save local reactions in a global state to avoid refetching
 */
const useReaction = (reactionId: string, initialValue: ReactionValue) => {
  const [reactions, setReactions] = useAtom(reactionsAtom)

  /**
   * Automatically add the reaction to global state with the default initial value
   */
  useEffect(() => {
    if (reactions[reactionId]) {
      return
    }

    setReactions((current) => ({
      ...current,
      [reactionId]: {
        initialValue: initialValue,
        value: null,
      },
    }))
  }, [reactions, reactionId, setReactions, initialValue])

  /**
   * Updated the current value for reaction
   */
  const setReactionValue = (val: ReactionValue) => {
    if (!reactions[reactionId]) {
      throw new Error('Reaction is not ready. Something with useEffect?')
    }

    setReactions((current) => ({
      ...current,
      [reactionId]: {
        ...current[reactionId],
        value: val,
      },
    }))
  }

  const reaction = reactions[reactionId]

  return {
    setReactionValue,
    value: reaction ? reaction.value : null,
    initialValue: reaction ? reaction.initialValue : initialValue,
  }
}

export default useReaction
