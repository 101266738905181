import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LineCard from '../_common/LineCard'

import BoardPublic from 'utils/classes/BoardPublic'
import ROUTES from 'config/routes'

type ComponentProps = {
  board: BoardPublic
  className?: string
}

const BoardCard = ({ board, className }: ComponentProps) => {
  const { t } = useTranslation('common')

  const getDescription = () => {
    if (board.createdByBio) {
      return board.createdByBio
    }

    if (board.collectionDescription) {
      return board.collectionDescription
    }

    return t(`collection.missingDescription.${board.collectionCategory}.description`)
  }

  return (
    <LineCard
      href={ROUTES.collection(board.collectionUsername, board.collectionSlug)}
      name={board.createdByName}
      description={getDescription()}
      image={board.collectionAvatarPublicUrl}
      countPositive={board.countPositive}
      countNegative={board.countNegative}
      countNeutral={board.countNeutral}
      className={className}
    />
  )
}

BoardCard.propTypes = {
  board: PropTypes.object,
  className: PropTypes.string,
}

export default BoardCard
