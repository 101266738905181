import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Image from 'next/image'

import BoardPublic from 'utils/classes/BoardPublic'
import { HOME_BOARD_IDS } from 'config/constants'

import GradientLabel from 'components/Label/GradientLabel'
import TabBar from 'components/TabBar'
import BoardCard from 'components/Card/Board'

type SecondSlideProps = {
  boards: BoardPublic[]
}

const SecondSlide = ({ boards }: SecondSlideProps) => {
  const { t } = useTranslation('pages')
  const { t: tb } = useTranslation('boards')

  const [tab, setTab] = useState<HOME_BOARD_IDS | string>(HOME_BOARD_IDS.exchanges)

  const showBoards = boards.filter((b) => b.boardId === tab)
  showBoards.splice(10)

  return (
    <div className="flex flex-col bg-white pb-8 xl:pb-16 2xl:pb-32">
      <div className="max-w-7xl w-full mx-auto px-4 mt-12 lg:mt-20">
        <div className="ml-2 mb-8">
          <Image alt="diamond" src={'/radicalIcons/retro/sunglasses.svg'} width={60} height={60} />
        </div>

        <h2 className="mb-8 font-extrabold font-heading">
          <div className="text-5xl sm:text-6xl md:text-7xl lg:text-7xl">
            <span className="mr-2">
              <GradientLabel isBlue>{t('index.slide4.title1')}</GradientLabel>{' '}
              {t('index.slide4.title2')}
            </span>
          </div>
        </h2>

        <div className="md:text-lg lg:text-xl mb-12 text-gray-500">
          {t('index.slide4.description')}
        </div>

        <TabBar
          value={tab}
          setValue={(newTab) => setTab(newTab)}
          withBorderBottom
          items={[
            {
              id: HOME_BOARD_IDS.exchanges,
              label: tb(`${HOME_BOARD_IDS.exchanges}.label`),
              isInfo: true,
            },
            {
              id: HOME_BOARD_IDS.nftMarketplaces,
              label: tb(`${HOME_BOARD_IDS.nftMarketplaces}.label`),
              isInfo: true,
            },
            {
              id: HOME_BOARD_IDS.other,
              label: tb(`${HOME_BOARD_IDS.other}.label`),
              isInfo: true,
            },
          ]}
        />

        {showBoards.length > 0 && (
          <div className="w-full mt-8">
            {showBoards.map((b: BoardPublic) => (
              <BoardCard
                className={classNames({
                  'mb-8 pb-8': true,
                  'border-b border-gray-100': b.id !== showBoards[showBoards.length - 1].id,
                })}
                key={b.id}
                board={b}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SecondSlide
