import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GetStaticProps } from 'next'

import supabase from 'config/supabase'

import { Root, HeadSeo } from 'components/Layout'
import NavBar from 'components/Navbar'
import ROUTES, { absoluteHref } from 'config/routes'
import Footer from 'components/Footer'
import BoardPublic from 'utils/classes/BoardPublic'
import ReviewPublic from 'utils/classes/ReviewPublic'

import Slide1 from './home/Slide1'
import Slide2 from './home/Slide2'
import Slide3 from './home/Slide3'
import Slide4 from './home/Slide4'

type HomeProps = {
  boardsData: any
  reviewsData: any
}

const Home = ({ boardsData, reviewsData }: HomeProps) => {
  const { t } = useTranslation('pages')

  const boards = useMemo(() => boardsData.map((b: any) => new BoardPublic(b)), [boardsData])
  const reviews = useMemo(() => reviewsData.map((r: any) => new ReviewPublic(r)), [reviewsData])

  return (
    <Root isHeightFull>
      <HeadSeo
        title={t('index.seo.title')}
        description={t('index.seo.description')}
        canonical={absoluteHref(ROUTES.home)}
      />

      <NavBar
        navChildClassnames={'w-full'}
        menuClassnames={'h-20 md:h-28'}
        isSmallCircles
        isWhite={false}
        hasBorderBottom={false}
        leftItemsClassname={'bg-white py-3 px-6 rounded-full border border-transparent'}
        leftItems={[
          {
            label: t('index.navbar.links.explore'),
            href: ROUTES.explore,
          },
          {
            label: t('index.navbar.links.forBusiness'),
            href: ROUTES.forBusiness,
          },
          {
            label: t('index.navbar.links.blog'),
            href: ROUTES.blog,
          },
          {
            type: 'divider',
            isMobile: true,
            isGuestOnly: true,
          },
          {
            label: t('index.navbar.links.login'),
            href: ROUTES.signin,
            isMobile: true,
            isGuestOnly: true,
          },
          {
            label: t('index.navbar.links.signup'),
            href: ROUTES.signup,
            isMobile: true,
            isGuestOnly: true,
          },
        ]}
      />

      <Slide1 />
      <Slide2 />
      <Slide3 reviews={reviews} />
      <Slide4 boards={boards} />

      <Footer />
    </Root>
  )
}

/**
 * query top collections
 */
export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { data } = await supabase.from('pub_boards').select('*')

  const { data: reviewData } = await supabase
    .from('pub_reviews')
    .select('*')
    .in('id', [
      'cl7py33ix007609me3x4xv5u6',
      'cl7ozffap019709mdo51q30r4',
      'cl7oyblb1006009mdlx36b9og',
      'cl8ld0bgd000409l6jluibc6e',
    ])

  return {
    props: {
      boardsData: data || [],
      reviewsData: reviewData || [],
    },
    revalidate: 3, // In seconds
  }
}

export default Home
