import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CircleLabel from 'components/Label/CircleLabel'

export type TabBarItem = {
  id: string
  label: string
  counter?: number
  isInfo?: boolean
  isOptional?: boolean
  isSuccess?: boolean
  isPurple?: boolean
  isPink?: boolean
  isOrange?: boolean
}

type TabBarProps = {
  value: string
  setValue: (val: string) => void
  items: TabBarItem[]
  withBorderBottom?: boolean
  className?: string
}

const Component = ({
  items,
  value,
  setValue,
  withBorderBottom = false,
  className,
}: TabBarProps) => {
  return (
    <div
      className={classnames(
        {
          'border-b border-gray-100': withBorderBottom,
        },
        className,
      )}
    >
      <ul className="flex flex-wrap sm:space-x-5">
        {items.map((i) => {
          const isActive = i.id === value

          return (
            <li className="w-1/2 sm:w-fit" key={i.id} onClick={() => setValue(i.id)}>
              <div
                className={classnames(
                  'text-lg cursor-pointer p-4 duration-200 h-full',
                  'flex items-center justify-between',
                  {
                    'md:border-b-2': withBorderBottom,
                  },
                  {
                    'border-transparent text-gray-400 hover:text-gray-500': isActive === false,
                    'font-semibold': isActive === true,
                    'text-gray-600 border-gray-400': isActive && i.isOptional,
                    'text-green-500 border-green-400': isActive && i.isSuccess,
                    'text-pink-500 border-pink-400': isActive && i.isPink,
                    'text-purple-500 border-purple-400': isActive && i.isPurple,
                    'text-sky-500 border-sky-400': isActive && i.isInfo,
                    'text-orange-500 border-orange-400': isActive && i.isOrange,
                  },
                )}
              >
                {i.label}

                {i.counter !== undefined && (
                  <CircleLabel
                    classNames={'ml-5 text-sm font-medium'}
                    isInfo={i.isInfo}
                    isSuccess={i.isSuccess}
                    isOptional={i.isOptional}
                    isPink={i.isPink}
                    isPurple={i.isPurple}
                    isOrange={i.isOrange}
                  >
                    {i.counter}
                  </CircleLabel>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Component.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      counter: PropTypes.number,
      isInfo: PropTypes.bool,
      isOptional: PropTypes.bool,
      isSuccess: PropTypes.bool,
      isPurple: PropTypes.bool,
      isPink: PropTypes.bool,
      isOrange: PropTypes.bool,
    }),
  ),
  withBorderBottom: PropTypes.bool,
  className: PropTypes.string,
}

export default Component
