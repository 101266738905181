import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

type VideoProps = {
  src: string
  poster?: string
  className?: string
  hideSignatureImages?: boolean
}

const Video = ({ src, className, hideSignatureImages = false, poster }: VideoProps) => {
  const videoSizeWhenGenerated = 512
  const signatureImageSizeWhenGenerated = 54
  const percentage = (signatureImageSizeWhenGenerated / videoSizeWhenGenerated) * 100

  return (
    <div className={classNames('relative', className)}>
      <video src={src} poster={poster} autoPlay muted loop />

      {hideSignatureImages && (
        <div
          className="absolute bg-white bottom-0 left-0"
          style={{
            width: `${percentage * 2}%`, // 2 signatures
            height: `${percentage}%`,
          }}
        />
      )}
    </div>
  )
}

Video.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  hideSignatureImages: PropTypes.bool,
  poster: PropTypes.string,
}

export default Video
