import supabase from 'config/supabase'
import { REVIEW_TYPES } from 'config/constants'
import ReviewCreatedByUser from './ReviewCreatedByUser'

/**
 * Supabase returns snake case data. We can not use prisma
 */
class ReviewPublic {
  private review: any

  constructor(review: any) {
    this.review = review
  }

  get id(): string {
    return this.review.id
  }

  get type(): REVIEW_TYPES {
    return this.review.type
  }

  get isNegative(): boolean {
    return this.type === REVIEW_TYPES.negative
  }

  get isNeutral(): boolean {
    return this.type === REVIEW_TYPES.neutral
  }

  get isPositive(): boolean {
    return this.type === REVIEW_TYPES.positive
  }

  get isMinted(): boolean {
    return this.mintPublicKey !== null
  }

  get localId(): number {
    return this.review.local_id
  }

  get note(): string {
    return this.review.note
  }

  get createdBy(): string {
    return this.review.created_by
  }

  get createdAt(): string {
    return this.review.created_at
  }

  get collectionId(): string {
    return this.review.collection_id
  }

  get collectionSlug(): string {
    return this.review.collection_id__slug
  }

  get collectionUsername(): string {
    return this.review.collection_id__username
  }

  get collectionName(): string {
    return this.review.collection_id__name
  }

  get collectionImage(): string {
    return this.review.collection_id__image
  }

  get collectionMintPublicKey(): string | null {
    return this.review.collection_id__mint_public_key
  }

  get collectionMintPublicKeyWithDots(): string | null {
    if (!this.collectionMintPublicKey) {
      return null
    }

    const first = this.collectionMintPublicKey.substring(0, 4)
    const last = this.collectionMintPublicKey.slice(-4)

    return first + '...' + last
  }

  get isCollectionMinted(): boolean {
    return this.collectionMintPublicKey !== null
  }

  get collectionImagePublicUrl(): string {
    const data = supabase.storage.from('collections').getPublicUrl(this.collectionImage)

    if (data.publicURL) {
      return data.publicURL
    }

    // shouldn't happen?
    return ''
  }

  get createdSource(): string | null {
    return this.review.created_source
  }

  get createdSourceHostname(): string | null {
    if (!this.createdSource) {
      return null
    }

    try {
      const { hostname } = new URL(this.createdSource)

      return hostname
    } catch (e) {
      return null
    }
  }

  get generatedMp4Url(): string {
    if (!this.review.generated_mp4) {
      return ''
    }

    const data = supabase.storage.from('reviews').getPublicUrl(this.review.generated_mp4)

    if (data.publicURL) {
      return data.publicURL
    }

    // shouldn't happen?
    return ''
  }

  get generatedJpgUrl(): string {
    if (!this.review.generated_jpg) {
      return ''
    }

    const data = supabase.storage.from('reviews').getPublicUrl(this.review.generated_jpg)

    if (data.publicURL) {
      return data.publicURL
    }

    // shouldn't happen?
    return ''
  }

  get images(): string[] {
    if (!this.review.images) {
      return []
    }

    const supabaseImages = this.review.images.split(',').map((i: string) => {
      const data = supabase.storage.from('reviews').getPublicUrl(i)

      if (data.publicURL) {
        return data.publicURL
      }

      throw new Error('Unable to read images. should not happen')
    })

    return supabaseImages
  }

  get createdByUser(): ReviewCreatedByUser {
    return new ReviewCreatedByUser(this.review)
  }

  get mintPublicKey(): string | null {
    return this.review.mint_public_key
  }

  get mintPublicKeyWithDots(): string | null {
    if (!this.mintPublicKey) {
      return null
    }

    const first = this.mintPublicKey.substring(0, 4)
    const last = this.mintPublicKey.slice(-4)

    return first + '...' + last
  }

  get countPositive(): number {
    return this.review.count_positive
  }

  get countNegative(): number {
    return this.review.count_negative
  }

  get countTotal(): number {
    return this.countNegative + this.countPositive
  }

  get arweaveJpgTx(): string | null {
    return this.review.arweave_jpg_tx
  }

  get arweaveJpgTxWithDots(): string | null {
    if (!this.arweaveJpgTx) {
      return null
    }

    const first = this.arweaveJpgTx.substring(0, 4)
    const last = this.arweaveJpgTx.slice(-4)

    return first + '...' + last
  }

  get arweaveMp4Tx(): string | null {
    return this.review.arweave_mp4_tx
  }

  get arweaveMp4TxWithDots(): string | null {
    if (!this.arweaveMp4Tx) {
      return null
    }

    const first = this.arweaveMp4Tx.substring(0, 4)
    const last = this.arweaveMp4Tx.slice(-4)

    return first + '...' + last
  }

  get arweaveMetadataTx(): string | null {
    return this.review.arweave_metadata_tx
  }

  get arweaveMetadataTxWithDots(): string | null {
    if (!this.arweaveMetadataTx) {
      return null
    }

    const first = this.arweaveMetadataTx.substring(0, 4)
    const last = this.arweaveMetadataTx.slice(-4)

    return first + '...' + last
  }

  get stickerPackName(): string | null {
    return this.review.sticker_pack_id__name
  }

  /**
   * If you change this don't forget the backend generateReviewMetadata ->  formatStickerName()
   */
  get stickerName(): string | null {
    if (!this.review.sticker_file) {
      return this.review.sticker_file
    }

    return (
      this.review.sticker_file
        .replace('.mp4', '')
        .replace('.jpg', '')
        // animated-emoji stickers are uploaded with that prefix
        .replace('AnimatedEmojies-512px-', '')
    )
  }

  get ranking(): number {
    const upvoteRanking = 0 + this.countPositive - this.countNegative

    return upvoteRanking
  }
}

export default ReviewPublic
