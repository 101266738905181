import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExternalLink from 'components/Link/ExternalLink'

type ComponentProps = {
  href: string
  icon: IconProp
  isVerified?: boolean
  verifiedColor?: string
}

const Component = ({ href, icon, isVerified = false, verifiedColor }: ComponentProps) => {
  const style: any = {}

  if (isVerified) {
    style['color'] = verifiedColor
  }

  return (
    <ExternalLink
      href={href}
      className="h-6 w-6 cursor-pointer whitespace-nowrap flex items-center justify-center bg-white rounded-full pointer-events-auto"
    >
      <FontAwesomeIcon
        className={classNames({
          'text-gray-400': isVerified === false || !verifiedColor,
        })}
        icon={icon}
        style={style}
      />
    </ExternalLink>
  )
}

Component.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.any,
  isVerified: PropTypes.bool,
  verifiedColor: PropTypes.string,
}

export default Component
