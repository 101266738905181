/* eslint-disable @next/next/no-img-element */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'
import RawAvatar from 'components/UserAvatar/RawAvatar'

import useImagePreloader from 'hooks/usePreloadImage'

type ComponentProps = {
  href: string
  name: string
  description: string
  image: string
  countPositive: number
  countNegative: number
  countNeutral: number
  className?: string
}

const LineCard = ({
  href,
  name,
  description,
  image,
  className,
  countPositive,
  countNegative,
  countNeutral,
}: ComponentProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const { imagesPreloaded } = useImagePreloader([
    '/emojis/negative96.webp',
    '/emojis/positive96.webp',
    '/emojis/neutral96.webp',
  ])

  return (
    <Link
      href={href}
      className={classNames(
        'bg-white duration-200 cursor-pointer w-full overflow-hidden',
        'flex flex-col md:flex-row items-center justify-between',
        {
          // 'hover:border-gray-200 hover:shadow-bottomGraySpread15': true,
        },
        className,
      )}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <div className="flex flex-col md:flex-row items-center my-6">
        <RawAvatar roundClassname={'rounded-2xl mb-12 md:mb-0'} src={image} alt={name} size={125} />

        <div className="pl-9 pr-7 mb-5 md:mb-0 text-left">
          <div className="text-lg font-semibold whitespace-nowrap text-ellipsis overflow-hidden mb-1">
            {name}
          </div>

          <div className="text-sm font-light text-gray-600 max-w-lg line-clamp-3">
            {description}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-around shrink-0 w-full md:w-64 mb-8 md:mb-0">
        <div className="text-center">
          <img
            alt={'positive'}
            width={45}
            height={45}
            src={
              isHovered && imagesPreloaded ? '/emojis/positive96.webp' : '/emojis/positive96.png'
            }
          />
          <div className="font-header font-medium text-2xl mt-2">{countPositive}</div>
        </div>

        <div className="text-center">
          <img
            alt={'neutral'}
            width={42}
            height={42}
            src={isHovered && imagesPreloaded ? '/emojis/neutral96.webp' : '/emojis/neutral96.png'}
          />
          <div className="font-header font-medium text-2xl mt-2">{countNeutral}</div>
        </div>

        <div className="text-center">
          <img
            alt={'negative'}
            width={45}
            height={45}
            src={
              isHovered && imagesPreloaded ? '/emojis/negative96.webp' : '/emojis/negative96.png'
            }
          />
          <div className="font-header font-medium text-2xl mt-2">{countNegative}</div>
        </div>
      </div>
    </Link>
  )
}

LineCard.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  countPositive: PropTypes.number,
  countNegative: PropTypes.number,
  countNeutral: PropTypes.number,
}

export default LineCard
