import supabase from 'config/supabase'
import { COLLECTION_TYPES } from 'config/constants'

/**
 * Supabase returns snake case data. We can not use prisma
 */
class BoardPublic {
  private data: any

  constructor(data: any) {
    this.data = data
  }

  get id(): string {
    return this.collectionId
  }

  get boardId(): string {
    return this.data.boards__id
  }

  get boardName(): string {
    return this.data.boards__name
  }

  get collectionId(): string {
    return this.data.collections__id
  }

  get collectionImage(): string {
    return this.data.collections__image
  }

  get collectionCategory(): string | COLLECTION_TYPES {
    return this.data.collections__category
  }

  get collectionAvatarPublicUrl(): string {
    const data = supabase.storage.from('collections').getPublicUrl(this.collectionImage)

    if (data.publicURL) {
      return data.publicURL
    }

    // shouldn't happen?
    return ''
  }

  get collectionName(): string {
    return this.data.collections__name
  }

  get collectionDescription(): string | null {
    return this.data.collections__description
  }

  get collectionSlug(): string {
    return this.data.collections__slug
  }

  get collectionUsername(): string {
    return this.data.collections__username
  }

  get createdById(): string {
    return this.data.createdby__id
  }

  get createdByUsername(): string {
    return this.data.createdby__username
  }

  get createdByName(): string {
    return this.data.createdby__name
  }

  get createdByBio(): string | null {
    return this.data.createdby__bio
  }

  get countPositive(): number {
    return this.data.count_positive
  }

  get countNegative(): number {
    return this.data.count_negative
  }

  get countNeutral(): number {
    return this.data.count_neutral
  }
}

export default BoardPublic
