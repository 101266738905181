import React from 'react'
import PropTypes from 'prop-types'
import { StatusCodes } from 'http-status-codes'

import supabase from 'config/supabase'
import ReviewPublic from 'utils/classes/ReviewPublic'

import Reactions, { ReactionValue } from '.'
import useReaction from './useReaction'
import UserAuth from 'utils/classes/UserAuth'

type ComponentProps = {
  userAuth: UserAuth
  review: ReviewPublic
  onSuccess: () => void
}

/**
 * Smart component - Reads from global state and uses useAuth
 *
 * 1. We don't refetch review counters manually
 * 2. We may revalidate user and reload it's reactions
 * 3. Review counters may be revalidated when going switching pages (navigating back)
 *
 * Therefore we need to save initial counters and work from that
 */
const UserReviewReactions = ({ review, userAuth, onSuccess }: ComponentProps) => {
  let countPositive = review.countPositive
  let countNegative = review.countNegative

  const getUserAuthReactionValue = () => {
    if (userAuth.negativeReviewReactions.includes(review.id)) {
      return ReactionValue.negative
    }

    if (userAuth.positiveReviewReactions.includes(review.id)) {
      return ReactionValue.positive
    }

    return ReactionValue.none
  }

  // We append counters. If review has changed (revalidated) - start new global state
  const reactionId = `for-review-${review.id}-${review.countPositive}-${review.countNegative}`

  // Global state handlers
  // Because we may revalidate user and reload it's reactions
  // we need to save the initial value to properly calculate the counters
  const { setReactionValue, value, initialValue } = useReaction(
    reactionId,
    getUserAuthReactionValue(),
  )

  // Reset counters. Exclude user auth input for easier calculations
  if (initialValue === ReactionValue.positive) {
    countPositive--
  } else if (initialValue === ReactionValue.negative) {
    countNegative--
  }

  const reactionValue = value === null ? initialValue : value

  // Overwrite with local values
  if (reactionValue === ReactionValue.positive) {
    countPositive++
  } else if (reactionValue === ReactionValue.negative) {
    countNegative++
  }

  return (
    <Reactions
      countPositive={countPositive}
      countNegative={countNegative}
      value={reactionValue}
      isDisabled={false}
      onChange={async (type: ReactionValue) => {
        let rpcName

        if (type === ReactionValue.positive) {
          rpcName = 'post_review_positive_reaction'
        } else if (type === ReactionValue.negative) {
          rpcName = 'post_review_negative_reaction'
        } else if (type === ReactionValue.none) {
          rpcName = 'delete_review_reaction'
        } else {
          throw new Error('Unknown Reaction Type')
        }

        setReactionValue(type)

        const response = await supabase.rpc(rpcName, {
          reviewid: review.id,
        })

        if (response.status === StatusCodes.NO_CONTENT) {
          onSuccess()
        }
      }}
    />
  )
}

UserReviewReactions.propTypes = {
  userAuth: PropTypes.object,
  review: PropTypes.object,
  onSuccess: PropTypes.func,
}

export default UserReviewReactions
