import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'

import Link from 'components/Link'
import Emoji from 'components/Emoji'
import ExternalLink from 'components/Link/ExternalLink'
import GradientLabel from 'components/Label/GradientLabel'
import ROUTES, { EXTERNAL_HREFS } from 'config/routes'

const FirstSlide = () => {
  const { t } = useTranslation('pages')

  return (
    <div className="flex w-full flex-col py-2 sm:pt-6 pb-16 md:pb-24 2xl:py-0 2xl:min-h-full-minus-header 2xl:-mt-3 bg-small-circles relative overflow-hidden">
      <div className={'2xl:mb-auto'} />

      <div className="max-w-6xl mx-auto w-full bg-transparent px-2 lg:px-8 relative">
        <div className={'relative text-center pt-4 2xl:pt-0 2xl:-mt-8 pb-16 bg-transparent z-30'}>
          <h1 className={'flex flex-col px-4 font-extrabold mb-4 xl:mb-6'}>
            <span className={'text-5xl sm:text-6xl md:text-7xl lg:text-8xl mb-1'}>
              <GradientLabel isGradient>{t('index.slide1.title1')}</GradientLabel>{' '}
              {t('index.slide1.title2')}
            </span>

            <span className={'text-2xl sm:text-3xl md:text-4xl lg:text-5xl'}>
              {t('index.slide1.subtitle1')}{' '}
              <GradientLabel isGradient>{t('index.slide1.subtitle2')}</GradientLabel>{' '}
              <Emoji className="rotate-12 inline-block" width={24} height={24} emoji="👑" />
            </span>
          </h1>

          <div
            className={
              'max-w-lg mx-auto md:text-lg mb-8 text-gray-500 bg-white py-3 px-4 inline-block rounded-full'
            }
          >
            {t('index.slide1.description')}
          </div>

          <div className={'px-4 md:px-0 relative'}>
            <span className="absolute top-0 -ml-24 -mt-12">
              <Image
                alt="slash"
                src={'/radicalIcons/lines/turny-dashed.svg'}
                width={50}
                height={100}
              />
            </span>

            <Link
              href={ROUTES.signup}
              className={
                'btn btn-lg bg-gray-700 text-white btn-shadow transition duration-300 ease-in-out mb-5 md:mb-0 w-full md:w-auto md:mr-2'
              }
            >
              {t('index.slide1.getStartedButton')}
            </Link>

            <Link
              href={ROUTES.forBusiness}
              className={
                'btn btn-lg btn-secondary btn-shadow transition duration-300 ease-in-out w-full md:w-auto'
              }
            >
              {t('index.slide1.forBusinessButton')}
            </Link>
          </div>

          <div className="mt-8 text-sm text-gray-500 font-light bg-white p-2 px-4 rounded-full inline-block">
            {t('index.slide1.quickStartLabel')}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="mb-8 text-gray-500 text-sm bg-white p-2 px-4 rounded-full select-none">
            {t('index.slide1.poweredByLabel')}
          </div>

          <div className="flex items-center space-x-2 sm:space-x-8">
            <ExternalLink
              href={EXTERNAL_HREFS.arweave}
              className="h-16 w-16 hover:scale-110 duration-200 cursor-pointer transform-gpu flex items-center border border-gray-100  justify-center bg-gradient-100 rounded-full"
            >
              <Image src="/blockchains/arweave.svg" alt="Arweave Logo" width={40} height={40} />
            </ExternalLink>

            <ExternalLink
              href={EXTERNAL_HREFS.bundlr}
              className="h-16 w-16 hover:scale-110 duration-200 cursor-pointer transform-gpu flex items-center justify-center bg-black rounded-full"
            >
              <Image src="/blockchains/bundlr.svg" alt="Bundlr Logo" width={35} height={35} />
            </ExternalLink>

            <ExternalLink
              href={EXTERNAL_HREFS.solana}
              className="h-16 w-16 hover:scale-110 duration-200 cursor-pointer transform-gpu flex items-center justify-center bg-black rounded-full"
            >
              <Image src="/blockchains/solana.svg" alt="Solana Logo" width={35} height={35} />
            </ExternalLink>

            <ExternalLink
              href={EXTERNAL_HREFS.metaplex}
              className="h-16 w-16 hover:scale-110 duration-200 cursor-pointer transform-gpu flex items-center justify-center border border-gray-100 bg-gradient-100 rounded-full"
            >
              <Image src="/blockchains/metaplex.svg" alt="Solana Logo" width={35} height={35} />
            </ExternalLink>
          </div>
        </div>
      </div>

      <div className={'2xl:mt-auto'} />
    </div>
  )
}

export default FirstSlide
