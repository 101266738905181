import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'

import GradientLabel from 'components/Label/GradientLabel'
import ReviewSlider from 'components/Sliders/ReviewSlider'

import ReviewPublic from 'utils/classes/ReviewPublic'

type ThirdSlideProps = {
  reviews: ReviewPublic[]
}

const ThirdSlide = ({ reviews }: ThirdSlideProps) => {
  const { t } = useTranslation('pages')

  return (
    <div className="flex flex-col border-t border-b bg-gradient py-16 md:py-32">
      <div className={'md:mb-auto'} />

      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-full mb-6 relative">
            <div className="px-4">
              <div className="flex items-center mb-10 space-x-1">
                <div className="h-10 w-10 flex items-center justify-center border border-gray-300 border-dashed rounded-full">
                  <Image alt="opensea" src={'/blockchains/opensea.svg'} width={35} height={35} />
                </div>

                <div className="h-10 w-10 flex items-center justify-center border border-gray-300 border-dashed rounded-full">
                  <Image
                    alt="magiceden"
                    src={'/blockchains/magiceden.png'}
                    className="rounded-full"
                    width={35}
                    height={35}
                  />
                </div>

                <div className="h-10 w-10 flex items-center justify-center border border-gray-300 border-dashed rounded-full">
                  <Image
                    alt="magiceden"
                    src={'/blockchains/solsea.svg'}
                    className="rounded-full"
                    width={35}
                    height={35}
                  />
                </div>

                <div className="h-10 w-10 flex items-center justify-center border border-gray-300 border-dashed rounded-full">
                  <Image
                    alt="magiceden"
                    src={'/blockchains/solanart.svg'}
                    className="rounded-full bg-white"
                    width={35}
                    height={35}
                  />
                </div>
              </div>

              <h2 className="mb-8 font-extrabold font-heading">
                <div className="text-5xl sm:text-6xl md:text-7xl lg:text-7xl flex flex-wrap items-center">
                  {t('index.slide3.title1')}
                  <Image
                    alt="arrow"
                    src={'/radicalIcons/arrows/arrow-short-zaggy.svg'}
                    className="-rotate-90"
                    width={8}
                    height={50}
                    style={{
                      margin: '0 30px',
                    }}
                  />
                  <GradientLabel isPink>{t('index.slide3.title2')}</GradientLabel>
                  <Image
                    alt="arrow"
                    src={'/radicalIcons/arrows/arrow-short-zaggy.svg'}
                    className="-rotate-90"
                    width={8}
                    height={50}
                    style={{
                      margin: '0 30px',
                    }}
                  />
                  {t('index.slide3.title3')}
                </div>
              </h2>

              <div className="md:text-lg lg:text-xl mb-8 text-gray-500 max-w-2xl">
                {t('index.slide3.description')}
              </div>
            </div>
          </div>

          <div className="w-full overflow-x-hidden xl:px-4">
            <ReviewSlider reviews={reviews} />
          </div>
        </div>
      </div>

      <div className={'md:mt-auto'} />
    </div>
  )
}

export default ThirdSlide
