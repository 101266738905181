import supabase from 'config/supabase'

/**
 * Supabase returns snake case data. We can not use prisma
 */
class ReviewCreatedByUser {
  private review: any

  constructor(review: any) {
    this.review = review
  }

  get username(): string {
    return this.review.created_by__username
  }

  get name(): string {
    return this.review.created_by__name
  }

  get prefixedUsername(): string {
    return `@${this.username}`
  }

  get publicKey(): string {
    return this.review.created_by__public_key
  }

  get publicKeyShort(): string {
    return this.publicKey.substring(0, 4)
  }

  get publicKeyWithDots(): string {
    const first = this.publicKey.substring(0, 4)
    const last = this.publicKey.slice(-4)
    return first + '...' + last
  }

  get discordUsername(): string | null {
    return this.review.created_by__discord_username
  }

  get twitterUsername(): string | null {
    return this.review.created_by__twitter_username
  }

  get githubUsername(): string | null {
    return this.review.created_by__github_username
  }

  get avatarPublicUrl(): string | undefined {
    if (this.review.created_by__avatar) {
      // custom uploaded avatar
      const data = supabase.storage.from('avatars').getPublicUrl(this.review.created_by__avatar)

      if (data.publicURL) {
        return data.publicURL
      }
    }
  }
}

export default ReviewCreatedByUser
