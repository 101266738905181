import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'utils/dayjs'
import { useTranslation } from 'react-i18next'

import Video from 'components/Video'
import Link from 'components/Link'
import StopPropagation from 'components/Link/StopPropagation'
import Reactions from 'components/Reactions'
import UserReviewReactions from 'components/Reactions/UserReviewReactions'

import ReviewPublic from 'utils/classes/ReviewPublic'
import RawAvatar from 'components/UserAvatar/RawAvatar'
import UserAvatar from 'components/UserAvatar'

import useAuth from 'hooks/useAuth'
import useHtmlEntityDecode from 'hooks/useHtmlEntityDecode'
import ROUTES from 'config/routes'
import { SOCIAL_COLORS } from 'config/constants'

// Local components
import CircleLink from './Link'

type ComponentProps = {
  review: ReviewPublic
  className?: string
  isInverse?: boolean
  showPublishedBy?: boolean
  showTwitter?: boolean
  showGithub?: boolean
  showDiscord?: boolean
}

const ReviewCard = ({
  review,
  className,
  isInverse = false,
  showPublishedBy = false,
  showTwitter = false,
  showGithub = false,
  showDiscord = false,
}: ComponentProps) => {
  const { t } = useTranslation('common')

  const { user, revalidateUser } = useAuth()

  const decodedNote = useHtmlEntityDecode(review.note)

  const username =
    review.createdByUser.publicKey === review.createdByUser.username
      ? review.createdByUser.publicKeyWithDots
      : review.createdByUser.prefixedUsername

  return (
    <div
      className={classNames(
        'bg-white border duration-200 p-4 cursor-pointer w-full rounded-3xl relative',
        {
          //'hover:border-green-200 hover:shadow-bottomGreenSpread15': review.isPositive,
          //'hover:border-orange-200 hover:shadow-bottomOrangeSpread15': review.isNeutral,
          //'hover:border-pink-200 hover:shadow-bottomPinkSpread15': review.isNegative,
        },
        className,
      )}
    >
      <Link
        className="absolute top-0 left-0 bottom-0 right-0"
        href={ROUTES.review(review.collectionUsername, review.collectionSlug, review.id)}
      />

      <div className="relative z-20 pointer-events-none">
        <div className="mb-3 flex items-center">
          {isInverse === false ? (
            <StopPropagation>
              <Link
                className="pointer-events-auto"
                href={ROUTES.profile(review.createdByUser.username)}
              >
                <UserAvatar user={review.createdByUser} size={50} />
              </Link>
            </StopPropagation>
          ) : (
            <StopPropagation>
              <Link
                className="pointer-events-auto"
                href={ROUTES.collection(review.collectionUsername, review.collectionSlug)}
              >
                <RawAvatar
                  src={review.collectionImagePublicUrl}
                  alt={review.collectionName}
                  size={50}
                />
              </Link>
            </StopPropagation>
          )}

          {isInverse === false ? (
            <StopPropagation className="mx-2 overflow-hidden">
              <Link
                className="pointer-events-auto"
                href={ROUTES.profile(review.createdByUser.username)}
              >
                <div className="font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
                  {review.createdByUser.name}
                </div>

                <div className="text-sm font-light text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">
                  {username}
                </div>
              </Link>
            </StopPropagation>
          ) : (
            <StopPropagation className="mx-2 overflow-hidden">
              <Link
                className="pointer-events-auto"
                href={ROUTES.collection(review.collectionUsername, review.collectionSlug)}
              >
                <div className="font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
                  {review.collectionName}
                </div>

                <div className="text-sm font-light text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">
                  {showPublishedBy
                    ? `${t('review.publishedBy')} ${username}`
                    : `@${review.collectionUsername}`}
                </div>
              </Link>
            </StopPropagation>
          )}

          <div className="grow flex items-center justify-end shrink-0">
            {review.createdByUser.twitterUsername && showTwitter && (
              <StopPropagation>
                <CircleLink
                  isVerified
                  verifiedColor={SOCIAL_COLORS.twitter}
                  href={`https://twitter.com/${review.createdByUser.twitterUsername}`}
                  icon={['fab', 'twitter']}
                />
              </StopPropagation>
            )}

            {review.createdByUser.discordUsername && showDiscord && (
              <StopPropagation>
                <CircleLink
                  isVerified
                  verifiedColor={SOCIAL_COLORS.discord}
                  href={`https://discord.com/users/${review.createdByUser.discordUsername}`}
                  icon={['fab', 'discord']}
                />
              </StopPropagation>
            )}

            {review.createdByUser.githubUsername && showGithub && (
              <StopPropagation>
                <CircleLink
                  isVerified
                  verifiedColor={SOCIAL_COLORS.github}
                  href={`https://github.com/${review.createdByUser.githubUsername}`}
                  icon={['fab', 'github']}
                />
              </StopPropagation>
            )}
          </div>
        </div>

        {review.isMinted && review.generatedMp4Url && (
          <div className="w-1/2 mx-auto relative aspect-square overflow-hidden z-30 mb-6">
            <Video
              src={review.generatedMp4Url}
              poster={review.generatedJpgUrl}
              hideSignatureImages
              className={'z-30'}
            />
          </div>
        )}

        <div className="mt-5 mb-3 whitespace-pre-line line-clamp-8">{decodedNote}</div>

        <div className="flex items-center justify-between">
          <div className="text-gray-400 text-sm">
            {dayjs(review.createdAt).format('MMM D, YYYY')}
          </div>

          <StopPropagation className="shrink-0">
            {user ? (
              <UserReviewReactions
                review={review}
                userAuth={user}
                onSuccess={() => revalidateUser()}
              />
            ) : (
              <Reactions
                countPositive={review.countPositive}
                countNegative={review.countNegative}
                isDisabled
              />
            )}
          </StopPropagation>
        </div>
      </div>
    </div>
  )
}

ReviewCard.propTypes = {
  review: PropTypes.object,
  className: PropTypes.string,
  isInverse: PropTypes.bool,
  showPublishedBy: PropTypes.bool,
  showTwitter: PropTypes.bool,
  showGithub: PropTypes.bool,
  showDiscord: PropTypes.bool,
}

export default ReviewCard
