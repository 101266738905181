import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ComponentProps = {
  className?: string
  countPositive?: number
  countNegative?: number
  onChange?: (type: ReactionValue) => void
  value?: ReactionValue
  isDisabled?: boolean
}

export enum ReactionValue {
  none,
  positive,
  negative,
}

/**
 * Dumb component.
 */
const Component = ({
  className,
  countPositive = 0,
  countNegative = 0,
  value = ReactionValue.none,
  onChange,
  isDisabled = true,
}: ComponentProps) => {
  const isPositive = value === ReactionValue.positive
  const isNegative = value === ReactionValue.negative

  const onClick = (clickedValue: ReactionValue) => {
    if (isDisabled || !onChange) {
      return
    }

    const newValue = clickedValue !== value ? clickedValue : ReactionValue.none
    onChange(newValue)
  }

  return (
    <div className={classNames('flex items-center pointer-events-auto', className)}>
      <div
        className={classNames('font-medium mr-2', {
          'text-gray-400 duration-200 hover:text-black':
            isPositive === false && isDisabled === false,
          'text-gray-400': isDisabled,
          'text-gray-600': isPositive,
        })}
        onClick={() => onClick(ReactionValue.positive)}
      >
        <span className="mr-1 text-sm">{countPositive}</span>
        {isPositive ? (
          <FontAwesomeIcon icon={['far', 'hand-point-up']} />
        ) : (
          <FontAwesomeIcon icon={['fat', 'hand-point-up']} />
        )}
      </div>
      <div
        className={classNames('font-medium', {
          'text-gray-400 duration-200 hover:text-black':
            isNegative === false && isDisabled === false,
          'text-gray-400': isDisabled,
          'text-gray-600': isNegative,
        })}
        onClick={() => onClick(ReactionValue.negative)}
      >
        <span className="mr-1 text-sm">{countNegative}</span>
        {isNegative ? (
          <FontAwesomeIcon icon={['far', 'hand-point-down']} />
        ) : (
          <FontAwesomeIcon icon={['fat', 'hand-point-down']} />
        )}
      </div>
    </div>
  )
}

Component.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  countPositive: PropTypes.number,
  countNegative: PropTypes.number,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default Component
